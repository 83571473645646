import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import {Logo, Card, Label, StyledInput} from '../PublicStyles';
import { Container } from '../Register.styles';
import { LoadingIcon, SuccessIcon, ErrorIcon, TitleContainer, Title, InputBox, Icon, StyledButton } from './styles'; // Import Card and FormControl directly
import { FaUser, FaLock } from 'react-icons/fa';
import logoImg from '../../assets/ArewaTv.png';
import Spinner from '../Spinner';
import DOMPurify from 'dompurify';
import { API_URL } from '../../config';


const AdminLogin = () => {

  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setScreenLoading(false); 
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); 

  const handleLogin = async (e) => {

    const sanitizedUsername = DOMPurify.sanitize(username);
    const sanitizedPassword = DOMPurify.sanitize(password);

    setLoading(true);
    setLoginSuccess(false);
    setError(false);

    e.preventDefault();

    try {
      // Validate input before sending the request
      if (!username || !password) {
        setError('Username and password are required.');
        setLoading(false);

        // Reset error message after 2 seconds
      setTimeout(() => {
        setError(null);
      }, 2000);

        return;
      }

      if (password.length < 8) {
        setError('Password must be at least 8 characters long.');
        setLoading(false);

          // Reset error message after 4 seconds
      setTimeout(() => {
        setError(null);
      }, 4000);

        return;
      }

      const {data} = await axios.post(`${API_URL}/public/admin/login`, { sanitizedUsername, sanitizedPassword });

      if (data.token && data.refreshToken) {
          Cookies.set('jwtToken', data.token, { secure: true, sameSite: 'Strict' });
          Cookies.set('refreshToken', data.refreshToken, { secure: true, sameSite: 'Strict' });
      }
      
      setLoginSuccess(true);
      setLoading(false);


      setTimeout(() => {
        setLoginSuccess(false);
        navigate('/admin/dashboard');
    }, 2000); 
        

    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      setError('Invalid username or password'); 
      setLoading(false);
          setTimeout(() => {
            setError(null);
          }, 4000);
    }
  };


  return (
    <Container>
     {screenLoading ? (
                <Spinner />
              ) : (   
      <Card>
      <Logo src={logoImg} alt="Logo" />
      
      <TitleContainer>
          <Title>Admin Login</Title>
        </TitleContainer>
        {/* Display error or success message if login fails */}
        {loginSuccess && <p style={{ color: 'green', backgroundColor: 'black', padding: '5px' }}>Login successful!</p>}
        {error && <p style={{ color: 'black' }}>{error}</p>}        
        <form onSubmit={handleLogin}>
        <InputBox>
          
            <Icon>
              <FaUser style={{ color: 'black', fontSize: '24px' }} />
            </Icon>
            <StyledInput
              type="text"
            //  placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <Label>Username</Label>
          </InputBox>

          <InputBox>
            <Icon>
              <FaLock style={{ color: 'black', fontSize: '20px' }} />
            </Icon>
            <StyledInput
              type="password"
            //  placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Label>Password</Label>
          </InputBox>
          
          <StyledButton type="submit" onClick={handleLogin} disabled={loading || loginSuccess}>
          {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Login'}
          {loading && <LoadingIcon />}
        </StyledButton>
        {/* {loginError && <p style={{ color: 'red' }}>{loginError}</p>} */}
          </form>
      </Card>
      )} 
    </Container>
  );
};


export default AdminLogin;
