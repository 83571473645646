import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import AddFundModel from './AddFundModel';
import styled from 'styled-components';
import logo from '../assets/ArewaVip.png';
import { FaEye, FaEyeSlash, FaTimes, FaSave, FaSearch } from 'react-icons/fa';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdDateRange, MdClose } from "react-icons/md";

const BgBig = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  @media (max-width: 768px) {
    //padding: 15px;
  }
  @media (max-width: 480px) {
    padding: 10px;
    //max-width: 90%;
  }
`;

const WalletCard = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  @media (max-width: 480px) {
    padding: 15px;
    border-radius: 8px;
  }
`;

const WelcomeUserDiv = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    font-size: 1.2em;
  }
`;

const BalanceAndAddButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
    font-size: 1.5em;
    @media (max-width: 480px) {
      font-size: 1.2em;
    }
  }
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
      width: 35px;
      height: 35px;
      font-size: 1.2em;
    }
  }
`;

const BalanceDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 1.5em;
    @media (max-width: 480px) {
      font-size: 1.2em;
    }
  }
`;

const TransactionDetailsDiv = styled.div`
  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.2em;
    @media (max-width: 480px) {
      font-size: 1em;
    }
  }
`;

const SearchAndDatePickerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Icon = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  padding: 8px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #ccc;
  width: 300px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const DateLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  color: #333;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 8px;
`;

const TransactionCard = styled.div`
  background-color: ${props => props.theme.colors.background2};
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: ${(props) => (
      props.status === 'success' ?  '1px solid #c3e6cb' :
      props.status === 'pending' ? '1px solid #e9edc0' :
      props.status === 'failed' ? '1px solid #f5c6cb' : '1px solid #c3e6cb')};

  &:hover {
    background-color: ${(props) => (
      props.status === 'success' ? '#c3e6cb' :
      props.status === 'pending' ? '#e9edc0' :
      props.status === 'failed' ? '#f5c6cb' : '#c3e6cb')};

    div {
      color: #333333;
    }
  }

  @media (max-width: 480px) {
    padding: 10px;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StatusDiv = styled.div`
  margin-top: 10px;
  padding: 5px;
  border-radius: 10px;
  color: #333333;
  background: ${({ status }) => 
    status === 'success' ? '#27d651' :
    status === 'pending' ? '#f5c91b' : 
    status === 'failed' ? '#fa5067' : 
    '#fff3cd'};
  span {
    color: #333333;
  }
  @media (max-width: 480px) {
    padding: 3px;
    border-radius: 8px;
    margin-top: 5px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddFundModelDiv = styled.div`
  position: fixed;
  top: 95px;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.text};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1100;
  padding: 10px;
  width: 100%;
  overflow: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 50;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  position: relative;
  text-align: center;

  &.hide-save-and-close {
    .save-button,
    .close-modal-button {
      display: none;
    }
  }

  @media (max-width: 480px) {
    padding: 15px;
    border-radius: 8px;
  }
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    width: 80px;
    margin-bottom: 15px;
  }
`;

const TransactionDetail = styled.div`
  margin: 10px 0;
  font-size: 16px;
  text-align: left;

  span {
    font-weight: bold;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const SaveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: #45a049;
  }

  &.save-button {
    display: block;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;

  &.close-modal-button {
    display: block;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;


const TransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: 480px) {
    //flex-direction: column;
  }
`;

const CreditAndDebitStatusDiv = styled.div`
  margin-top: 10px;
  padding: 5px;
  border-radius: 10px;
  //color: #333333;
  
  span {
    //color: #333333;
    color: ${({ status }) => 
    status === 'credit' ? '#27d651' :
    status === 'pending' ? '#f5c91b' : 
    status === 'debit' ? '#fa5067' : 
    '#fff3cd'};
  }
  @media (max-width: 480px) {
    padding: 3px;
    border-radius: 8px;
    margin-top: 5px;
  }
`;

const SpansDiv = styled.div`
  display: flex;
  flex-direction: column;
`;


const UserWalletDetails = () => {
  const navigate = useNavigate();
  //const [userDetails, setUserDetails] = useState('');
  const [username, setUsername] = useState('');
  const [userWalletBalance, setUserWalletBalance] = useState('');
  const [showBalance, setShowBalance] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [showAddFundModel, setShowAddFundModel] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const transactionsPerPage = 5;
  const transactionsListRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  useEffect(() => {
    const fetchUserWalletDetails = async () => {
      const token = await getValidToken(navigate, true);
      console.log('Token to Verify:', token);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/User/wallet/details`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });
          const { username, wallet } = response.data || {};
          if (username && wallet) {
            setUsername(username);
            setUserWalletBalance(wallet);
          } else {
            console.error('Invalid user data received');
          }
        } catch (error) {
          console.error('Error fetching user details:', error.response?.data || error.message);
        }
      }
    };
    fetchUserWalletDetails();
  }, [navigate]);
  

  // useEffect(() => {
  //   const fetchUserTransactions = async (page = 1, limit = 5) => {
  //     const token = await getValidToken(navigate, true);
  //     if (token) {
  //     try {
  //       const response = await axios.get(`${API_URL}/User/transactions?page=${page}&limit=${limit}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         withCredentials: true,
  //       });
  //       setTransactions(response.data.transactions);
  //       setTotalTransactions(response.data.total);
  //     } catch (error) {
  //       console.error('Error fetching transactions:', error.response?.data || error.message);
  //     }
  //   }
  //   };
  //   fetchUserTransactions(currentPage, transactionsPerPage);
  // }, [currentPage, navigate]);
    
  const fetchUserTransactions = useCallback(async (page = 1, limit = 5, startDate = null, endDate = null, searchTerm = '') => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/User/transactions`, {
          params: {
            page,
            limit,
            startDate,
            endDate,
            search: searchTerm,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setTransactions(response.data.transactions);
        setTotalTransactions(response.data.total);
      } catch (error) {
        console.error('Error fetching transactions:', error.response?.data || error.message);
      }
    }
  }, []);
  
  useEffect(() => {
    fetchUserTransactions(currentPage, 5, startDate, endDate, searchTerm);
  }, [currentPage, navigate, startDate, endDate, searchTerm, fetchUserTransactions]);
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    fetchUserTransactions(1, 5, startDate, endDate, e.target.value);
  };
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
    fetchUserTransactions(1, 5, date, endDate, searchTerm);
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
    fetchUserTransactions(1, 5, startDate, date, searchTerm);
  };
  
  
  

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
    if (datePickerVisible) setDatePickerVisible(false);
    setSearchTerm('');
  };

  const toggleDatePicker = () => {
    setDatePickerVisible(!datePickerVisible);
    if (searchVisible) setSearchVisible(false);
    setStartDate('');
    setEndDate('');
  };

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleCloseModal = () => {
    setSelectedTransaction(null);
  };

  const handleSaveAsImage = () => {
    const transactionDetails = document.getElementById('transaction-details');

    // Hide the Save Button and Close Icon
    transactionDetails.classList.add('hide-save-and-close');

    html2canvas(transactionDetails).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'transaction-details.png';
      link.click();

      // Show the Save Button and Close Icon
      transactionDetails.classList.remove('hide-save-and-close');
    });
  };

  // Pagination
  const totalPages = Math.ceil(totalTransactions / transactionsPerPage);
  //const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    transactionsListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

// Format wallet balance with commas
const formatWallet = (wallet) => {
  // Check if wallet is a valid number or string that can be converted to a number
  if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
    return 'Invalid earnings';
  }

  // Convert earnings to number and ensure it's a valid number
  wallet = parseFloat(wallet);
  if (isNaN(wallet) || !isFinite(wallet)) {
    return 'Invalid wallet';
  }

  // Convert earnings to string with 2 decimal places
  let walletStr = wallet.toFixed(2);

  // Split wallet into whole and decimal parts
  const parts = walletStr.split('.');
  let wholePart = parts[0];
  const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Add commas for thousands, millions, etc.
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  wholePart = wholePart.replace(regex, ',');

  // Return formatted earnings
  return wholePart + decimalPart;
};

  // Format earnings with custom function
  const formattedWalletBalance = formatWallet(userWalletBalance);

return (
    <BgBig>
    <Container>
      <WalletCard>
        <WelcomeUserDiv>Barkan Da Zuwa, {username}</WelcomeUserDiv>
        <BalanceAndAddButtonDiv>
          <BalanceDiv>
            <span>{showBalance ? `N${formattedWalletBalance}` : '*********'}</span>
            <button onClick={toggleBalanceVisibility}>
              {showBalance ? <FaEyeSlash /> : <FaEye />}
            </button>
          </BalanceDiv>
          <button onClick={() => setShowAddFundModel(true)}>+</button>
        </BalanceAndAddButtonDiv>
      </WalletCard>

      <hr />

      <TransactionDetailsDiv ref={transactionsListRef}>
        <h3>Transaction Details</h3>

      <SearchAndDatePickerDiv>

      <DatePickerContainer>
        <Icon onClick={toggleDatePicker}>
          {datePickerVisible ? <MdClose /> : <MdDateRange />}
        </Icon>
        <DatePickerWrapper visible={datePickerVisible}>
          <DateLabel>Start Date:</DateLabel>
          <StyledDatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            isClearable
            dateFormat="yyyy/MM/dd"
            placeholderText="Select start date"
          />
          <DateLabel>End Date:</DateLabel>
          <StyledDatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            isClearable
            dateFormat="yyyy/MM/dd"
            placeholderText="Select end date"
          />
        </DatePickerWrapper>
      </DatePickerContainer>

        <SearchContainer>
        <SearchInput
          visible={searchVisible}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search..."
        />
        <Icon onClick={toggleSearch}>
          {searchVisible ? <FaTimes /> : <FaSearch />}
        </Icon>
      </SearchContainer>
      </SearchAndDatePickerDiv>
        
        {transactions.map((transaction, index) => (
        <TransactionCard key={index} onClick={() => handleTransactionClick(transaction)} status={transaction.status}>
          {transaction.type === 'transaction' ? (
            <TransactionDetails>
              <SpansDiv>
                <span>{transaction.post_name}</span>
                <span>Price: N{transaction.amount}</span>
                <span>Created At: {new Date(transaction.created_at).toLocaleString()}</span>
              </SpansDiv>
              <CreditAndDebitStatusDiv status={transaction.transaction_type}>
              <span>{transaction.transaction_type === 'debit' ? 'Debit' : 'Credit'}</span>
              </CreditAndDebitStatusDiv>
              <StatusDiv status={transaction.status}>
                <span>{transaction.status}</span>
              </StatusDiv>
            </TransactionDetails>
          ) : (
            <TransactionDetails>
              <SpansDiv>
                <span>Credit from Monnify</span>
                <span>Amount: N{transaction.amount}</span>
                <span>Transaction Reference: {transaction.transaction_reference}</span>
                <span>Created At: {new Date(transaction.created_at).toLocaleString()}</span>
              </SpansDiv>
              <StatusDiv status="Credit">
                <span>Credit</span>
              </StatusDiv>
            </TransactionDetails>
          )}
        </TransactionCard>
      ))}
        <PaginationWrapper>
          <Pagination>
            <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            {Array.from({ length: Math.ceil(totalTransactions / 5) }, (_, index) => (
              <PageNumber
                key={index + 1}
                onClick={() => handlePageClick(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </PageNumber>
            ))}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === Math.ceil(totalTransactions / 5)}>
              Next
            </PaginationButton>
          </Pagination>
        </PaginationWrapper>
      </TransactionDetailsDiv>

      {showAddFundModel && (
          <AddFundModelDiv>
            <CloseButton onClick={() => setShowAddFundModel(false)}>
              <FaTimes />
            </CloseButton>
            <AddFundModel closeModal={() => setShowAddFundModel(false)} fromParent={true}/>
          </AddFundModelDiv>
      )}

      {selectedTransaction && (
        <ModalOverlay>
          <ModalContent id="transaction-details">
            <CloseModalButton className="close-modal-button" onClick={handleCloseModal}>
              <FaTimes />
            </CloseModalButton>
            <Logo src={logo} alt="Company Logo" />
            <h2>Transaction Details</h2>
            <TransactionDetail>
              <span>Username:</span> {username}
            </TransactionDetail>
            <TransactionDetail>
              <span>Post Name:</span> {selectedTransaction.post_name}
            </TransactionDetail>
            <TransactionDetail>
              <span>Amount:</span> N{selectedTransaction.amount}
            </TransactionDetail>
            <TransactionDetail>
              <span>Created At:</span> {new Date(selectedTransaction.created_at).toLocaleString()}
            </TransactionDetail>
            <TransactionDetail>
              <span>Status:</span> {selectedTransaction.status}
            </TransactionDetail>
            <SaveButton className="save-button" onClick={handleSaveAsImage}>
              <FaSave /> Save as Image
            </SaveButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
    </BgBig>
  );
};

export default UserWalletDetails;
