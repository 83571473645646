import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ErrorMessageModal = ({ errorMessage, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderErrorContent = () => {
    if (errorMessage === 'Insufficient funds') {
      return (
        <>
          <ErrorText>
            {t('insufficient funds')}
          </ErrorText>
          <Button onClick={() => navigate('/add-fund')}>
            {t('fund wallet')}
          </Button>
        </>
      );
    }
    return <ErrorText>{errorMessage}</ErrorText>;
  };

  return (
    <ModalContainer>
      <HeaderSection>
        <div style={{ width: '100%', color: 'red', fontWeight: 'bold' }}>
          <p>Error</p>
        </div>
        <CloseDiv onClick={onClose}>
          <MdClose size={24} color="#fff" />
        </CloseDiv>
      </HeaderSection>
      <ModalContent>{renderErrorContent()}</ModalContent>
    </ModalContainer>
  );
};

export default ErrorMessageModal;

// Styled components
const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  z-index: 1351;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.danger};
  color: ${(props) => props.theme.colors.text};
  padding: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background2};
  height: 50px;
  padding: 10px;
`;

const CloseDiv = styled.div`
  background-color: red;
  border-radius: 10px;
  position: absolute;
  font-weight: 800;
  right: 0;
  margin-right: 10px;
  padding: 1px;
  cursor: pointer;
`;

const ErrorText = styled.p`
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

