import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { API_URL, baseURL } from '../config';
import { Helmet } from 'react-helmet';
import ShowStatusModel from './ShowStatusModel';
import CommentsModal from './CommentsModal';
import incrementViewCount from '../utils/incrementView';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { SkeletonWrapper } from './SkeletonWrapper';
import { Container, Header, SpinnerContainer, ImageContainer, Thumbnail, BrandLogo, Span, StyledHandshakeIcon, StyledFaRegThumbsUp, StyledHandIcon, Heading1, Heading2, StyledLink, StyledButtonK, ContentContainer } from './PostDetails.Styles';
import { Container1, PostTitle, AuthorDetailsDiv, ProfileImage, ViewsDiv, AuthorInfo, PostDetailsDiv, StyledButton, CommentsModalDiv, LikeDiv, PlayerDiv, ThumbnailDiv, ContentWrapper, KalloDaSaukeAnanCard} from './PurchaseModel'; 
import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';
import CustomYouTubePlayer from './CustomYouTubePlayer';
import RecommendedPostsList from './RecommendedPostsList'; 
import ScrollToTop from './ScrollToTop';
import BookReader from './BookReader';
import PurchaseModel from './PurchaseModel';
import WebViewModal from './WebViewModal';
import Spinner from './Spinner';
import SpinnerArewaLogo from './SpinnerArewaLogo';
import { Close, CloseIcon } from './PublicStyles';
import { FcLike } from "react-icons/fc";
import { FaDownload, FaRegHeart} from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa6";
import { MdOutlinePayments } from "react-icons/md";
import { TbTrident } from "react-icons/tb";
import { FaCirclePlay } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";

const PostDetails = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState('');
  const [liked, setLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [totalVideoShare, setTotalVideoShare] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isFreePost, setIsFreePost] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [isFollowing, setIsFollowing] = useState(false);
  const [postPrice, setPostPrice] = useState(null);
  const [postTitle, setPostTitle] = useState(null);
  const [postAuthor, setPostAuthor] = useState(null);
  const [author, setAuthor] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [trailerLink, setTrailerLink] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false); 
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showHandshake, setShowHandshake] = useState(false);
  const [showThumbsUp, setShowThumbsUp] = useState(false);
  const [mainViews, setMainViews] = useState(null);
  const [shouldIncrement, setShouldIncrement] = useState(false);
  const effectRan = useRef(false);
  const topRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openPurchaseModel, setOpenPurchaseModel] = useState(false);
  const postUrl = `${window.location.origin}/post/${post.id}`;
  const postThumbnail = `${baseURL}${post.thumbnail}`;

  const [webViewVisible, setWebViewVisible] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');

  console.log("PostDetails postId:", postId); // Log to verify extraction



  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (effectRan.current || !shouldIncrement) return; 
    const incrementView = async () => {
      try {
        await incrementViewCount('main', postId, navigate);
        console.log('View incremented and session storage updated');
      } catch (error) {
        console.error('Error incrementing view count:', error);
      }
    };

    incrementView();
    effectRan.current = true; // Mark the effect as run

    // Clean-up function
    return () => {
      console.log('Cleaning up');
      // Any necessary cleanup logic can go here
    };
  }, [postId, shouldIncrement, navigate]);

  useEffect(() => {
    if (isFreePost || isPurchased || isSubscribed) {
      setShouldIncrement(true);
    }
  }, [isFreePost, isPurchased, isSubscribed]);

  useEffect(() => {
    const fetchsetMainViews = async () => {
        try {
          const response = await axios.get(`${API_URL}/public/post/${postId}/main`);
          setMainViews(response.data.totalViews);
        } catch (error) {
          console.error('Error fetching trailer views:', error);
        } finally {
          setIsLoading(false);
        }
    };

    fetchsetMainViews();
  }, [postId]);


  // Function to extract YouTube video ID from link
  const getYouTubeVideoId = (link) => {
    const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return videoIdMatch ? videoIdMatch[1] : '';
  };


useEffect(() => {
  const fetchData = async () => {
    if (!postId) {
      console.error('Error: postId is null or undefined');
      setIsLoading(false);
      return;
    }

    try {
      // Step 1: Check the post price
      const priceCheckResponse = await axios.get(`${API_URL}/public/price/check/${postId}`);
      const { title, price, thumbnail, trailer_link, author_id } = priceCheckResponse.data;
      setPostTitle(title);
      setPostPrice(price);
      setIsFreePost(parseFloat(price) <= 0.00);
      setThumbnail(thumbnail);

      const trailerVideoId = getYouTubeVideoId(trailer_link);
      setTrailerLink(trailerVideoId);
      setPostAuthor(author_id);
      console.log('Price check successful:', priceCheckResponse.data);

      if (!parseFloat(price) <= 0.00) {
        // Step 2: Check if the post is purchased if not free
        const token = await getValidToken(navigate, false);
        if (token) {
          const purchaseCheckResponse = await axios.get(`${API_URL}/purchase/check/${postId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });

          const { purchased, resetDateValid } = purchaseCheckResponse.data;
          setIsPurchased(purchased && (resetDateValid || resetDateValid === null));
          console.log('Purchase check successful:', purchaseCheckResponse.data);
        }
      }
      

      // Step 3: Check if the user is subscribed
      if (author_id) {
        const token = await getValidToken(navigate, false);
        if (token) {
          const subscriptionCheckResponse = await axios.get(`${API_URL}/subscription/check/${author_id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });

          const { subscribed } = subscriptionCheckResponse.data;
          setIsSubscribed(subscribed);
          console.log('Subscription check successful:', subscriptionCheckResponse.data);
        }
      }

      // Step 4: Fetch the post details if the post is free, purchased, or the user is subscribed
      if (parseFloat(price) <= 0.00 || isPurchased || isSubscribed) {
        const response = await axios.get(`${API_URL}/public/posts/${postId}`);
        setPost(response.data);
        const videoId = getYouTubeVideoId(response.data.video_link);
        setVideoLink(videoId);
        console.log('Fetched post:', response.data);
      }
       // Set loading to false after completing data fetching
       setIsLoading(false);

       // Determine if to open the purchase model
       setOpenPurchaseModel(!(parseFloat(price) <= 0.00 || isPurchased || isSubscribed));
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      setErrorMessage(error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  fetchData();
}, [postId, navigate, isPurchased, isSubscribed, openPurchaseModel]);


  // Additional logging to debug
  useEffect(() => {
    console.log('Post updated:', post);
    console.log('isFreePost:', isFreePost);
    console.log('isPurchased:', isPurchased);
    console.log('isLoading:', isLoading);
  }, [post, isFreePost, isPurchased, isLoading]);

// other useEffect hooks for fetching additional data
useEffect(() => {
  if (!postAuthor) return;

  const fetchAuthorDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/public/author/${postAuthor}/details`);
      setAuthor(response.data);
    } catch (error) {
      console.error('Error fetching author details:', error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  fetchAuthorDetails();
}, [postAuthor]);

useEffect(() => {
  if (!postAuthor) return;

  const fetchFollowerCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/public/users/${postAuthor}/followers/count`);
      setFollowerCount(response.data.followerCount);
    } catch (error) {
      console.error('Error fetching follower count:', error);
    } finally {
      setIsLoading(false);
    }
  };

  fetchFollowerCount();
}, [postAuthor]);

// Fetch total likes
useEffect(() => {
  const fetchLikes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/public/posts/${postId}/likes/count`);
      setTotalLikes(response.data.likesCount);
    } catch (error) {
      console.error('Error fetching total likes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  fetchLikes();
}, [postId, navigate]);

const fetchTotalComments = useCallback(async () => {
  setIsLoading(true);
  try {
    const response = await axios.get(`${API_URL}/public/posts/${postId}/comments/count`);
    setTotalComments(response.data.commentsCount);
  } catch (error) {
    console.error('Error fetching total comments:', error);
  } finally {
    setIsLoading(false);
  }
}, [postId]);

useEffect(() => {
  fetchTotalComments();
}, [postId, fetchTotalComments]);

useEffect(() => {
  if (!postAuthor) return;

  const fetchFollowStatus = async () => {
    setIsLoading(true);
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/users/${postAuthor}/follow_status`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setIsFollowing(response.data.isFollowing);
      } catch (error) {
        console.error('Error fetching follow status:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const fetchLikeStatus = async () => {
    setIsLoading(true);
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/posts/${postId}/like_status`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setLiked(response.data.isLiked);
      } catch (error) {
        console.error('Error fetching like status:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  fetchFollowStatus();
  fetchLikeStatus();
}, [postId, postAuthor, navigate]);


// Check if the user is logged in
useEffect(() => {
  const token = getValidToken(navigate, false);
  if (token) {
    setIsLoggedIn(true);
  } else {
    setIsLoggedIn(false);
  }
}, [navigate]);

useEffect(() => {
  if (showCommentsModal || showModal) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  // Cleanup on unmount
  return () => {
    document.body.style.overflow = 'auto';
  };
}, [showCommentsModal, showModal]);


  const openCommentsModal = () => {
    setShowCommentsModal(true);
  };

  const closeCommentsModal = () => {
    setShowCommentsModal(false);
  };

  const handleCloseIcon = () => {
    navigate('/');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openWebView = (url) => {
    setCurrentUrl(url);
    setWebViewVisible(true);
  };

  const closeWebView = () => {
    setWebViewVisible(false);
  };

  const handleFollow = async () => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    try {
      await axios.post(`${API_URL}/users/${postAuthor}/follow`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
      setIsFollowing(true);
      setFollowerCount(prevLikes => prevLikes + 1);
      setShowHandshake(true);
      setTimeout(() => {
        setShowHandshake(false);
        setShowThumbsUp(true);
      }, 2000);
      
      setTimeout(() => {
        setShowThumbsUp(false);
      }, 4000);

    } catch (error) {
      console.error('Error following user:', error);
    } 
  }
  };

  const handleUnfollow = async () => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    try {
      await axios.delete(`${API_URL}/users/${postAuthor}/unfollow`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
      setIsFollowing(false);
      setFollowerCount(prevLikes => prevLikes - 1);
    } catch (error) {
      console.error('Error unfollowing user:', error);
    } 
  }
  };

  const handleLike = async () => {
    if (!post) return;
    const token = await getValidToken(navigate, true); 
      if (token) {
    try {
      await axios.post(`${API_URL}/post/${post.id}/like`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
      setLiked(true);
      setTotalLikes(prevLikes => prevLikes + 1);
    } catch (error) {
      console.error('Error liking post:', error);
    }
  }
  };
  

  const handleUnlike = async () => {
    if (!post) return;
    const token = await getValidToken(navigate, true); 
      if (token) {
    try {
      const response = await axios.delete(`${API_URL}/post/${post.id}/unlike`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
  
      if (response.status === 200) {
        setLiked(false);
        setTotalLikes(prevLikes => prevLikes - 1);
      } else {
        console.error('Failed to unlike post:', response.statusText);
      }
    } catch (error) {
      console.error('Error unliking post:', error);
    }
  } 
  };

  const handleShareClick = async () => {
    const shareData = {
      title: post.title,
      text: post.title,
      url: postUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(postUrl);
        alert('Link copied to clipboard!');
      } catch (error) {
        console.error('Error copying link:', error);
      }
    } else {
      // Fallback for unsupported browsers
      const textArea = document.createElement('textarea');
      textArea.value = postUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Link copied to clipboard!');
      } catch (error) {
        console.error('Error copying link:', error);
      }
      document.body.removeChild(textArea);
    }
  };

  // generate and download Link For post.video not post.video_link
  const fetchDownloadLink = async (fileUrl) => {
    const token = await getValidToken(navigate, true); // Ensure getValidToken is defined
    try {
      const response = await axios.get(`${API_URL}/download-video`, {
        params: { fileUrl },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        return response.data.downloadUrl;
      } else {
        throw new Error('Failed to fetch download link');
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
      return null;
    }
  };

  const fetchDownloadLinkForYouTube = async (fileUrl) => {
    const token = await getValidToken(navigate, true);
    try {
      const response = await axios.get(`${API_URL}/Ytube/download/save`, {
        params: { url: fileUrl },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        return response.data.downloadLink;
      } else {
        throw new Error('Failed to fetch YouTube download link');
      }
    } catch (error) {
      console.error('Error fetching YouTube download link:', error);
      return null;
    }
  };

  const handleDownload = async (fileUrl, isYouTube = false) => {
    try {
      const downloadLink = isYouTube
        ? await fetchDownloadLinkForYouTube(fileUrl)
        : await fetchDownloadLink(fileUrl);
  
      console.log('Signed download URL:', downloadLink);
      if (downloadLink) {
        // Create an anchor element and set the href to the download link
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = fileUrl.split('/').pop(); // Set the file name for download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const handleClick = () => {
    if (post?.video || post?.audio) {
      handleDownload(post?.video || post?.audio);
    } else if (post?.video_link) {
      handleDownload(post?.video_link, true);
    } else {
      console.error('No valid video link found');
    }
  };
  

  if (isLoading) {
    return (
      <SpinnerContainer>
        <SpinnerArewaLogo />
      </SpinnerContainer>
    );
  }

  
  if ( openPurchaseModel ) { // !isPurchased && !isFreePost && !isSubscribed
    console.log('Rendering PurchaseModel');
    return (
      <Container>
          <PurchaseModel 
          postId={postId} 
          postTitle={postTitle} 
          price={postPrice} 
          VideoId={trailerLink}
          trailerYoutubeLink={trailerLink}  
          thumbnail={thumbnail} 
          postAuthor={author}
          authorId={postAuthor} 
          followers={followerCount} 
          isFollowing={isFollowing} 
          handleFollow={handleFollow} 
          handleUnfollow={handleUnfollow}
          liked={liked}
          handleUnlike={handleUnlike}
          handleLike={handleLike}
          totalLikes={totalLikes}
          totalVideoShare={totalVideoShare}
          totalComments={totalComments}
          handleDownload={handleClick}
          setOpenPurchaseModel={setOpenPurchaseModel}
        />
      </Container>
    );
  }

  if (!post) { 
    return (
      <SpinnerContainer>
        <h2 style={{color: props => props.theme.colors.text}}>Loading Post...</h2> <SpinnerArewaLogo />
    </SpinnerContainer>
    )
  }

  return (
    <Container1>
      <Header>
        <BrandLogo onClick={() => navigate('/')}>
          <h2>Arewa <Span>Kallo</Span></h2>
        </BrandLogo>
        <Close onClick={handleCloseIcon}>
          <CloseIcon />
        </Close>
      </Header>
  
      {isFreePost || isLoggedIn || isPurchased || isSubscribed ? (
        <>
        {post.video && post.video.includes('Videos/') ? (
        <>
        <PlayerDiv>
          <VideoPlayer videoUrl={`${baseURL}${post.video}`} thumbnailUrl={`${baseURL}${post.thumbnail}`}/>
          <ViewsDiv>
                <div>{mainViews} Views</div>
          </ViewsDiv>
        </PlayerDiv>
        </>
      ) : post.video_link && (post.video_link.includes('youtube.com') || post.video_link.includes('youtu.be') || post.video_link.includes('facebook.com')) ? (
        <>
        <PlayerDiv>
          <CustomYouTubePlayer videoId={post.video_link} isFacebook={post.video_link} />
          <ViewsDiv>
                <div>{mainViews} Views</div>
          </ViewsDiv>
        </PlayerDiv>
        </>
      ) : post.audio && post.audio.includes('Audios/') ? (
        <>
        <PlayerDiv>
          <ImageContainer>
            <AudioPlayer audioUrl={`${baseURL}${post.audio}`} thumbnail={`${baseURL}${post.thumbnail}`} />
          </ImageContainer>
          <ViewsDiv>
                <div>{mainViews} Views</div>
          </ViewsDiv>
        </PlayerDiv>
        </>
      ) : (
        <>
          {post.content && (
            <>
            
            <ImageContainer style={{marginTop: '50px', marginBottom: '20px'}}>
                <Thumbnail src={`${baseURL}${post.thumbnail}`} alt="Thumbnail" />
              </ImageContainer>
             

              <div style={{marginBottom: '10px'}}>
              <BookReader content={post.content} title={post.title} totalRead={mainViews} />
              </div>
      
              <ViewsDiv>
                <div>{mainViews} Views</div>
              </ViewsDiv>
            </>
          )} : {(post.video_link.includes('drive.google.com') || post.download_link.includes('mediafire.com')) &&  (
            <>
            {/* <ThumbnailDiv> */}
              <ImageContainer style={{marginTop: '50px', marginBottom: '20px'}}>
                <Thumbnail src={`${baseURL}${post.thumbnail}`} alt="Thumbnail" />
              </ImageContainer>
              <ViewsDiv>
                <div>{mainViews} Views</div>
              </ViewsDiv>
            {/*  </ThumbnailDiv> */}   
            </>
          )}
        </>
      )}

<ContentWrapper ref={topRef} postContent={post.content || (post.video_link.includes('drive.google.com') || post.download_link.includes('mediafire.com'))}>
        <PostTitle><h3>{post.title}</h3></PostTitle>          
          <div style={{ padding: '10px' }}>
            <AuthorDetailsDiv>
              <div>
              <Link to={`/author/${post.author_id}`}>
                <ProfileImage src={`${baseURL}${author.profile_picture}`} alt="Profile" />
                </Link>
                <AuthorInfo>
                  <h4>{author.username}</h4>
                  <p>{followerCount} Followers</p> 
                </AuthorInfo>
                <div>{showThumbsUp && <StyledFaRegThumbsUp />}</div>
              </div>
              <div>{showHandshake && <StyledHandshakeIcon />}</div>
              <div>
                {isFollowing ? (
                  <StyledButton onClick={handleUnfollow}>
                    {/* <h4>Unfollow</h4> */}
                    <FaUserCheck color='green' size={15}/>
                  </StyledButton>
                ) : (
                  <StyledButton onClick={handleFollow}>
                    <h4><b>+</b>Follow</h4>
                  </StyledButton>
                )}
              </div>
            </AuthorDetailsDiv>
  
            <hr style={{ color: props => props.theme.colors.text }} />

            <PostDetailsDiv>
            <div>
            {liked === undefined  ? (
                  <SkeletonWrapper height="20px" width="20px" />
                ) : (
            liked ? (
              <LikeDiv onClick={handleUnlike}><FcLike /></LikeDiv>
            ) : (
              <LikeDiv onClick={handleLike}><FaRegHeart /></LikeDiv>
            )
            )}
            {totalLikes === undefined  ? (
                  <SkeletonWrapper height="10px" width="20px" />
                ) : (
            <span>{totalLikes}</span> )}
            </div>

            <div>
            <LikeDiv onClick={openCommentsModal}><FaRegCommentDots /></LikeDiv>
            {totalComments === undefined  ? (
                  <SkeletonWrapper height="10px" width="20px" />
                ) : (
                  <span>{totalComments}</span> )}
            </div>

            <div>
            <Helmet>
              <meta property="og:title" content={post.title} />
              <meta property="og:description" content={post.description || post.title} />
              <meta property="og:image" content={postThumbnail} />
              <meta property="og:url" content={postUrl} />
              <meta property="og:type" content="website" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={post.title} />
              <meta name="twitter:description" content={post.description || post.title} />
              <meta name="twitter:image" content={postThumbnail} />
              <meta name="twitter:url" content={postUrl} />
            </Helmet>
              <LikeDiv onClick={handleShareClick}>
                <IoShareSocialOutline />
              </LikeDiv>
              {totalVideoShare === undefined ? (
                <SkeletonWrapper height="10px" width="20px" />
              ) : (
                <span>{totalVideoShare}</span>
              )}
            </div>

            <div onClick={handleClick} style={{cursor: 'pointer'}}>
              <FaDownload size={18} color='' />
            </div>

            </PostDetailsDiv>
  
            {!(post.content || post.video || post.audio) && (
  <>
    {((post.video_link && post.video_link.includes('drive.com')) || (post.download_link && post.download_link.includes('mediafire.com'))) ? (
      <>
      <KalloDaSaukeAnanCard>
        <Heading1>Kallo Anan <StyledHandIcon /></Heading1>
        <Heading2>
          {post.video_link && (
            <StyledLink href={post.video_link} rel="noopener noreferrer">
              <StyledButtonK>{post.title}</StyledButtonK>
              <FaCirclePlay />
            </StyledLink>
          )}
        </Heading2>
        <Heading1>Sauke Anan <StyledHandIcon /></Heading1>
        <Heading2>
          {post.download_link && (
            <StyledLink href={post.download_link} rel="noopener noreferrer">
              <StyledButtonK>{post.title}</StyledButtonK>
              <IoMdDownload />
            </StyledLink>
          )}
        </Heading2>
      </KalloDaSaukeAnanCard>  
      </>
    ) : (
      <>
        {!(post.video_link.includes('youtube.com') || post.video_link.includes('youtu.be')) && (
          <>
          <KalloDaSaukeAnanCard>
            <Heading1>Kallo Anan <StyledHandIcon /></Heading1>
            <Heading2>
              {post.video_link && (
                <StyledLink href={post.video_link} rel="noopener noreferrer">
                  <StyledButtonK>{post.title}</StyledButtonK>
                  <FaCirclePlay />
                </StyledLink>
              )}
            </Heading2>
            <Heading1>Sauke Anan <StyledHandIcon /></Heading1>
            <Heading2>
              {post.download_link && (
                <StyledLink href={post.download_link} rel="noopener noreferrer">
                  <StyledButtonK>{post.title}</StyledButtonK>
                  <IoMdDownload />
                </StyledLink>
              )}
            </Heading2>
          </KalloDaSaukeAnanCard>
          </>
        )}
      </>
    )}
  </>
)}

            {/* {post.content && (
              <BookReader content={post.content} />
            )} */}

<hr />            
            {/* Render Recommended Posts Here */}
            <RecommendedPostsList postIdprops={postId} />

            {/* Render the ScrollToTop button */}
            <ScrollToTop />

            {showCommentsModal && (
              <CommentsModalDiv>
                <CommentsModal CloseFunction={closeCommentsModal} postId={postId} authorUsername={author.username} setTotalCommentsProps={setTotalComments} />
              </CommentsModalDiv>
            )}

            {showModal && <ShowStatusModel message={errorMessage} onClose={handleCloseModal} />}
          </div>
          </ContentWrapper>
        </>
      ) : (
        <>
          <SpinnerContainer>
            <h2 style={{color: props => props.theme.colors.text}}>Loading Post...</h2> 
            <Spinner />
          </SpinnerContainer>
        </>
      )}
    </Container1>
  );
  
};

export default PostDetails;
