import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { API_URL, baseURL } from '../config';
import { Helmet } from 'react-helmet';
import Avatar from './Avatar';
import incrementViewCount from '../utils/incrementView';
import { SkeletonWrapper } from './SkeletonWrapper';
import CustomYoutubePlayer from './CustomYouTubePlayer';
import FacebookPlayer from './FacebookPlayer';
import VideoPlayer from './VideoPlayer';
import ErrorMessageModal from './ErrorMessageModal';
import CommentsModal from './CommentsModal';
import SubscriptionModal from './SubscriptionModal';
import styled from 'styled-components';
import { Close, CloseIcon, LoadingIcon, SuccessIcon, ErrorIcon, } from './PublicStyles';
import { FcLike } from "react-icons/fc";
import { FaRegHeart, FaDownload } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaUserCheck } from "react-icons/fa6";
import { MdOutlinePayments } from "react-icons/md";
import { TbTrident } from "react-icons/tb";

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
`;

const Header = styled.div`
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 10px; /* Add padding for some space around the content */
  z-index: 27;
`;

const PlayerDiv = styled.div`
  position: fixed;
  top: 50px; 
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 26; /* Adjust z-index as needed */
  @media (min-width: 768px) {
  position: relative;
  top: 50px; 
  left: 0;
  }
`;

const ThumbnailDiv = styled.div`
  //position: fixed;
  top: 50px; 
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  //z-index: 26; /* Adjust z-index as needed */
  @media (min-width: 768px) {
  position: relative;
  left: 0;
  }
`;

const ContentWrapper = styled.div`
  padding-top: ${({ postContent }) => postContent ? '0' : '300px'}; /* Conditional padding based on postContent validity */

  @media (min-width: 768px) {
    padding-top: ${({ postContent }) => postContent ? '0' : '50px'};
  }
`;

const KalloDaSaukeAnanCard = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 2px solid green;
  background-color: ${(props) => props.theme.colors.background2};
  margin: 10px;
  margin-bottom: 50px;
`;


const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  //padding: 3px;
  overflow: hidden;
  background-color: black;
  border-radius: 7px;
  border: 2px solid ${props => props.theme.colors.text};

  @media (min-width: 768px) {
    height: 320px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Fit the image within the container */
`;


const PostTitle = styled.div`
  padding: 5px;
  color: ${props => props.theme.colors.text};
`;

const AuthorDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  color: ${props => props.theme.colors.text};

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const ViewsDiv = styled.div`
  width: 100%;
  text-align: right;
  font-size: 20px;
  margin-right: 10px;

  div {
    margin-right: 10px;
    color: ${props => props.theme.colors.text};
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.text};
`;

const PostDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 10px;
  color: ${props => props.theme.colors.text};

  div {
    display: flex;
    flex-direction: column;
  }
`;

const PriceAndPurchaseDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  color: ${props => props.theme.colors.text};

  p {
    margin-right: 1rem;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
  }

  button {
    background-color: ${props => props.theme.colors.danger};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
  }
`;

const StyledButton = styled.button`
  background-color: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.text};
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const StyledPrice = styled.div`
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
`;

const CommentsModalDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  //margin: 10px;
  background-color: ${props => props.theme.colors.background};
  //padding: 20px;
  border-top: 1px solid #ccc : ${props => props.theme.colors.text};
  border-radius: 6px;
  z-index: 28;
`;

const LikeDiv = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const BrandLogo = styled.div`
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 25;
`;

export {Container1, Header, PostTitle, AuthorDetailsDiv, ProfileImage, ViewsDiv, AuthorInfo, PostDetailsDiv, PriceAndPurchaseDiv, StyledButton, StyledPrice, CommentsModalDiv, LikeDiv, PlayerDiv, ThumbnailDiv, ContentWrapper, ScrollToTopButton, KalloDaSaukeAnanCard};

const PurchaseModel = ({ 
  postId, 
  postTitle, 
  price, 
  VideoId,
  trailerYoutubeLink,
  thumbnail, 
  postAuthor,
  authorId, 
  followers, 
  isFollowing, 
  handleFollow, 
  handleUnfollow,
  liked,
  handleUnlike,
  handleLike,
  totalLikes,
  totalComments,
  totalVideoShare,
  comments,
  setOpenPurchaseModel
 }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showSubscriptionsModel, setShowSubscriptionsModel] = useState(false);
  const [purchasedSuccessfull, setPurchasedSuccessfull] = useState(false);
  const postUrl = `${window.location.origin}/post/${postId}`;
  const postThumbnail = `${baseURL}${thumbnail}`;
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current || !postId) return; // If the effect has already run or should not increment, do nothing

    const incrementView = async () => {
      try {
        await incrementViewCount('trailer', postId);
        console.log('View incremented and session storage updated');
      } catch (error) {
        console.error('Error incrementing view count:', error);
      }
    };

    incrementView();
    effectRan.current = true; // Mark the effect as run

    // Clean-up function
    return () => {
      console.log('Cleaning up');
      // Any necessary cleanup logic can go here
    };
  }, [postId]);

  const openCommentsModal = () => {
    setShowCommentsModal(true);
  };

  const closeCommentsModal = () => {
    setShowCommentsModal(false);
  };
  

  // const handlePurchase = async () => {
  //   setIsLoading(true);

  //   const token = await getValidToken(navigate, true); 
  //     if (token) {

  //   try {
      
  //     const response = await axios.post(`${API_URL}/post/${postId}/purchase`, null, {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       },
  //       withCredentials: true
  //     });

      
  //     if (response.status === 200) {
  //       navigate(`/post/${postId}`);
        
  //       setPurchasedSuccessfull(true)
  //       setIsLoading(false);

  //       setTimeout(() => {
  //         setPurchasedSuccessfull(false);
  //     }, 2000);

  //     setOpenPurchaseModel(false);
        
  //       window.location.reload();
  //     } else {
  //       console.error('Purchase failed:', response.data.error);
  //       setErrorMessage(response.data.error || 'An error occurred.');
  //       setIsLoading(false);
  //       setTimeout(() => {
  //         setErrorMessage('');
  //     }, 8000);
  //     }
  //   } catch (error) {
  //     console.error('Error purchasing post:', error);
  //     setErrorMessage(error.response?.data?.error || 'An error occurred.');
  //     setIsLoading(false);
  //     setTimeout(() => {
  //         setErrorMessage('');
  //     }, 8000);
  //   }
  // } 

  // };
  const handlePurchase = async () => {
  setIsLoading(true);
  const token = await getValidToken(navigate, true);

  if (token) {
    try {
      const response = await axios.post(`${API_URL}/post/${postId}/purchase`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        // Purchase was successful
        navigate(`/post/${postId}`);
        setPurchasedSuccessfull(true);
        setIsLoading(false);

        setTimeout(() => {
          setPurchasedSuccessfull(false);
        }, 2000);

        setOpenPurchaseModel(false);
        window.location.reload();
      }
    } catch (error) {
      // Extract and set the error message
      const errorMessage = error.response?.data?.error || 'An error occurred.';
      console.error('Error purchasing post:', errorMessage);

      // Set error message in state
      setErrorMessage(errorMessage);
      setIsLoading(false);

      // Clear the message after some time
      setTimeout(() => {
        setErrorMessage('');
      }, 8000);
    }
  } else {
    // Handle token retrieval failure
    console.error('Failed to retrieve token.');
    setErrorMessage('Authentication error. Please try again.');
    setIsLoading(false);

    setTimeout(() => {
      setErrorMessage('');
    }, 8000);
  }
};


  const handleCloseModal = () => {
    setErrorMessage('');
  };

  const handleCloseIcon = () => {
    navigate('/');
  };

  const handleShowSubscriptions = () => {
    setShowSubscriptionsModel(true);
  }

  const handleCloseSubscriptionsModel = () => {
    setShowSubscriptionsModel(false);
  }

  const handleShareClick = async () => {
    const shareData = {
      title: postTitle,
      text: postTitle,
      url: postUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(postUrl);
        alert('Link copied to clipboard!');
      } catch (error) {
        console.error('Error copying link:', error);
      }
    } else {
      // Fallback for unsupported browsers
      const textArea = document.createElement('textarea');
      textArea.value = postUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Link copied to clipboard!');
      } catch (error) {
        console.error('Error copying link:', error);
      }
      document.body.removeChild(textArea);
    }
  };

  
  const handleDownloadClick = async () => {
    const token = await getValidToken(navigate, true)
    if (token) {
      alert('Zaka Fara Siya Kafin Ka Sauke')
    } else {
      return;
    }
  }

  return (
    <Container1>

      <Header>
      <BrandLogo onClick={() => navigate('/')}><h2>Arewa <Span>Kallo</Span></h2></BrandLogo>
      <Close onClick={handleCloseIcon}>
      {/* <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} /> */}
      <CloseIcon />
      </Close>
      </Header>

        {VideoId && VideoId.includes('facebook') ? (
          <FacebookPlayer videoId={VideoId} />
        ) : VideoId && trailerYoutubeLink ? (
          <CustomYoutubePlayer videoId={VideoId} />
        ) : VideoId && VideoId.includes('/Videos') ? (
            <VideoPlayer videoId={VideoId} />
        ) : (
          <ImageContainer>
            <Thumbnail src={`${baseURL}${thumbnail}`} alt="Thumbnail" />
          </ImageContainer>
        )}

      <PostTitle>
        {postTitle === undefined  ? (
            <SkeletonWrapper style={{ width: '200px', height:'25px' }} />
          ) : (
      <h3>{postTitle}</h3> )}
      </PostTitle>

      <div style={{padding: '10px',}}>
      <AuthorDetailsDiv>
      <div>
      {!postAuthor ? (
            <>
              <SkeletonWrapper style={{ width: '40px', height:'40px', borderRadius:'50%' }}  />
              <AuthorInfo>
              <SkeletonWrapper style={{ width: '100px', height:'20px' }} />
              <SkeletonWrapper style={{ width: '80px', height:'15px' }} />
              </AuthorInfo>
            </>
          ) : (
            <>
              <Avatar 
                username={postAuthor?.username} 
                profilePicture={postAuthor.profile_picture ? `${baseURL}${postAuthor.profile_picture}` : null} 
              />
              <AuthorInfo>
                <h4>{postAuthor.username}</h4>
                <p>{followers} Followers</p>
              </AuthorInfo>
            </>
          )}
        </div>

        <div>
          <StyledButton onClick={handleShowSubscriptions}>Subscribe</StyledButton>
        </div>

        <div>
        {isFollowing === undefined  ? (
            <SkeletonWrapper style={{ width: '70px', height:'30px' }} />
          ) : (
            isFollowing ? (
              <StyledButton onClick={handleUnfollow}>
                {/* <h4>Unfollow</h4> */}
                <FaUserCheck color='green' size={15} />
              </StyledButton>
            ) : (
              <StyledButton onClick={handleFollow}>
                <h4>Follow</h4>
              </StyledButton>
            )
          )}
      </div>
      </AuthorDetailsDiv>

      <hr style={{ color: `${props => props.theme.colors.text}` }} />

      <PriceAndPurchaseDiv>
      {price === undefined  ? (
        <>
            <SkeletonWrapper style={{ width: '70px', height:'30px' }} />
            <SkeletonWrapper style={{ width: '70px', height:'30px' }} />
        </>    
          ) : (
        <>    
            <StyledPrice>{t('price')}: ₦{price}</StyledPrice>
            <button onClick={handlePurchase} disabled={isLoading || purchasedSuccessfull}>
          {purchasedSuccessfull ? <SuccessIcon /> : errorMessage ? <ErrorIcon /> : <h3>{t('buy now')}</h3>}
          {isLoading && <LoadingIcon />} 
        </button> 
        </>
        )}
      </PriceAndPurchaseDiv>

      <hr style={{ color: `${props => props.theme.colors.text}` }} />

      <PostDetailsDiv>
      <div>
      {liked === undefined  ? (
            <SkeletonWrapper style={{ width: '20px', height:'20px' }} />
          ) : (
      liked ? (
        <LikeDiv onClick={handleUnlike}><FcLike /></LikeDiv>
      ) : (
        <LikeDiv onClick={handleLike}><FaRegHeart /></LikeDiv>
      )
      )}
      {totalLikes === undefined  ? (
            <SkeletonWrapper style={{ width: '20px', height:'10px' }} />
          ) : (
      <span>{totalLikes}</span> )}
      </div>

      <div>
      <LikeDiv onClick={openCommentsModal}><FaRegCommentDots /></LikeDiv>
      {totalComments === undefined  ? (
            <SkeletonWrapper style={{ width: '20px', height:'10px' }} />
          ) : (
            <span>{totalComments}</span> )}
      </div>

            <div>
            <Helmet>
              <meta property="og:title" content={postTitle} />
              <meta property="og:description" content={'description' || postTitle} />
              <meta property="og:image" content={postThumbnail} />
              <meta property="og:url" content={postUrl} />
              <meta property="og:type" content="website" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={postTitle} />
              <meta name="twitter:description" content={'post.description' || postTitle} />
              <meta name="twitter:image" content={postThumbnail} />
              <meta name="twitter:url" content={postUrl} />
            </Helmet>
              <LikeDiv onClick={handleShareClick}>
                <IoShareSocialOutline />
              </LikeDiv>
              {totalVideoShare === undefined ? (
                <SkeletonWrapper height="10px" width="20px" />
              ) : (
                <span>{totalVideoShare}</span>
              )}
            </div>
            <div onClick={handleDownloadClick}>
              <FaDownload size={18} color='' />
            </div>
      </PostDetailsDiv>

      {showCommentsModal && (
        <CommentsModalDiv>
          <CommentsModal CloseFunction={closeCommentsModal} comments={comments} postId={postId} authorUsername={postAuthor.username} isFromPurchaseModal={true} />
        </CommentsModalDiv>
      )}

      {showSubscriptionsModel && (
        <SubscriptionModal authorId={authorId} onClose={handleCloseSubscriptionsModel} authorUsername={postAuthor.username} />
      )}

      {errorMessage && (
      <ErrorMessageModal errorMessage={errorMessage} onClose={handleCloseModal}/>
      )}    
      </div>
    </Container1>
  ); 
};
  

export default PurchaseModel;
