import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

// Wrapper to maintain aspect ratio
const VideoPlayerDiv = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (default for most videos) */
  background-color: black;
  border-radius: 7px;
  border: 2px solid ${props => props.theme.colors.text};

  @media (min-width: 768px) {
    padding-top: 56.25%; /* Maintain the aspect ratio */
  }

  @media (min-width: 1242px) {
    padding-top: 56.25%; /* Maintain the aspect ratio */
  }
`;

// Video overlay to fit the aspect-ratio box
const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FacebookPlayer = ({ videoId }) => {
  const playerRef = useRef(null);

  return (
    <VideoPlayerDiv>
      <VideoWrapper>
        <ReactPlayer
          ref={playerRef}
          url={videoId}
          controls={true}
          width="100%"
          height="100%"
          config={{
            facebook: {
              appId: 'your-facebook-app-id',
            },
          }}
        />
      </VideoWrapper>
    </VideoPlayerDiv>
  );
};

export default FacebookPlayer;
