import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_URL, baseURL } from '../config';
import styled, { keyframes } from 'styled-components';
import { getValidToken } from '../utils/auth';
import  Spinner  from './Spinner';
import Button from './Button';
import TutorialVideoModel from './TutorialVideoModel';
import { TiWorld } from "react-icons/ti";
import { TbArrowBackUp } from "react-icons/tb";

import { PlayAudioButton } from './PublicStyles';
import { BiMoviePlay } from "react-icons/bi";
import { FaCamera, FaUpload } from "react-icons/fa";
import { MdClose } from 'react-icons/md';

import GenerateVirtualAccounts from './GenerateVirtualAccounts';
import ErrorMessageModal from './ErrorMessageModal';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const HeaderSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const CloseDiv = styled.div`
  background-color: red;
  border-radius: 10px;
  position: absolute;
  font-weight: 800;
  right: 0;
  margin-left: 10px;
  padding: 1px;
  cursor: pointer;
`;

const Card = styled.div`
  background: ${(props) => props.theme.colors.background2};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: center;
  position: relative;
`;

const Title = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: ${(props) => props.theme.colors.text};

  p {
  color: ${(props) => props.theme.colors.text};
  }
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const InfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.text};
  margin: 10px 0px;
`

const Note = styled.p`
  color: red;
`;

const BankInfo = styled.p`
  margin: 10px 0;
  color: ${(props) => props.theme.colors.text};
`;

const Hr = styled.hr`
  margin: 40px 0;
  color: ${(props) => props.theme.colors.text};
`;

const FileInput = styled.input`
  display: none;
`;

const Label1 = styled.label`
  display: inline-block;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  padding: 10px 20px;
  border-radius: 15px;
  margin: 10px 0px;
  cursor: pointer;
  gap: 10px;
`;

const CopyToClipBoardButton = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  margin-top: 10px;
`;

// Container for previewing the image 
const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 20px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Image preview styling
const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
  object-fit: contain;
`;

// Message to indicate status (pending, approved, rejected)
const StatusMessage = styled.div`
  display: flex;
  gap: 10px;

  p {
  color: ${props => props.status === 'pending' ? '#FFA500' : props.status === 'rejected' ? '#FF0000' : '#008000'};
  font-weight: bold;
  margin-bottom: 10px;
  gap: 5px;
}
`;

const MessageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
  padding: 5px;

  p {
    color: red;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: ${(props) => props.theme.colors.background2};
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.text};
  resize: none; /* Prevents resizing */
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
`;

// Button styling
const Button1 = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding: 10px 20px;
  margin: 10px 5px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;


const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: ${props => (props.showVideoModal || props.status ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
`;

const AddFundModel = ({ toggleModal, fromParent }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [userWalletAccounts, setUserWalletAccounts] = useState(null);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [receipt, setReceipt] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState(null);
  const [preview, setPreview] = useState(null);
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('')
  const [receiptStatus, setReceiptStatus] = useState(null);
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  const [showVideoModal, setShowVideoModal] = useState(false);

  
  const handleShowModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  const handleClose = () => {
  if (fromParent) {
    toggleModal?.(); // Safe call to avoid errors if toggleModal is undefined
  } else {
    navigate?.(-1); // Safe call to ensure navigate exists
  }
};
  

  const fetchUserWalletAccounts = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    try {
      const response = await axios.get(`${API_URL}/User/Wallet/Accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUserWalletAccounts(response.data.accounts);
      } else {
        setErrorMessage(response.data.error || 'An error occurred.');
        setTimeout(() => {
          setErrorMessage('');  
          }, 4000);
      }
    } catch (error) {
        setErrorMessage(error.response?.data?.message || 'An error occurred.');

        setTimeout(() => {
        setErrorMessage('');  
        }, 4000);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const fetchReceiptStatus = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    
    if (!token) {
      setErrorMessage('Authentication failed.');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
      return;
    }
    
    try {
      const response = await axios.get(`${API_URL}/receipts/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        const { status, receiptPath, message, code } = response.data;
        
        setReceiptStatus(status);
        
        if (status === 'pending' || status === 'waiting' || status === 'rejected') {
          setPreview(receiptPath);
          setReceiptUrl(receiptPath);
          setCode(code);
        }
        
        if (status === 'rejected') {
          setMessage(message);
        }
        
      } else {
        throw new Error(response.data.error || 'An error occurred.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred.');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);  

  useEffect(() => {
    fetchUserWalletAccounts();
    fetchReceiptStatus();
  }, [fetchUserWalletAccounts, fetchReceiptStatus]);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setReceipt(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const isFirebaseUrl = (url) => {
    // Check if the preview URL is already a full URL (from Firebase)
    return url?.startsWith('Receipts') || url?.startsWith('https');
  };

  const handleRetake = () => {
    setPreview(null);
    setReceipt(null);
  };

  const handleSubmit = async () => {
    if (!code && !receipt) {
      setErrorMessage('Zaka dauki hoton receipt ko code ko sunan da zamu gani tukunna');
      setTimeout(() => {
        setErrorMessage('');
      }, 8000);
      return;
    } 
  
    const formData = new FormData();
    if (receipt) {
    formData.append('receipt', receipt);
    }
    formData.append('code', code);

    const token = await getValidToken(navigate, true);
    if (token) {
      setIsLoadingSubmit(true);
    try {
      
      await axios.post(`${API_URL}/upload-receipt`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      setSuccess('Receipt uploaded successfully!');
      fetchReceiptStatus();
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (error) {
      setErrorMessage('Failed to upload receipt.');
      setTimeout(() => {
        setErrorMessage('');  
        }, 5000);
    } finally {
      setIsLoadingSubmit(false);
    }
  }
  };

  const copyToClipboard = async (text) => {
  try {
    // Check if the clipboard API is available
    if (navigator?.clipboard?.writeText) {
      // Ensure text is not undefined or null
      if (text) {
        await navigator.clipboard.writeText(text);
        alert('Copied to clipboard!');
      } else {
        alert('No text to copy.');
      }
    } else {
      // Fallback for older browsers or unsupported platforms
      fallbackCopyToClipboard(text);
    }
  } catch (error) {
    console.error('Error copying text to clipboard:', error);
    alert('Failed to copy to clipboard.');
  }
};

// Fallback function to copy text to clipboard using document.execCommand
const fallbackCopyToClipboard = (text) => {
  if (!text) {
    alert('No text to copy.');
    return;
  }

  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';  // Prevent scrolling to bottom
  textArea.style.left = '-9999px'; // Move off-screen
  document.body.appendChild(textArea);
  textArea.select();
  try {
    const successful = document.execCommand('copy');
    if (successful) {
      alert('Copied to clipboard!');
    } else {
      alert('Failed to copy to clipboard.');
    }
  } catch (error) {
    console.error('Fallback: Error copying text to clipboard:', error);
    alert('Failed to copy to clipboard.');
  } finally {
    document.body.removeChild(textArea);
  }
};


  // generate and download Link
  const fetchDownloadLink = async (fileUrl) => {
    const token = await getValidToken(navigate, true); 
    try {
      const response = await axios.get(`${API_URL}/download-receipt`, {
        params: { fileUrl },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        return response.data.downloadUrl;
      } else {
        throw new Error('Failed to fetch download link');
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
      return null;
    }
  };
  
  const handleDownload = async (fileUrl) => {
    try {
      const downloadLink = await fetchDownloadLink(fileUrl);
      console.log('signed download url:', downloadLink);
      if (downloadLink) {
        // Create an anchor element and set the href to the download link
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = fileUrl.split('/').pop(); // Set the file name for download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };
  
  
  if (isLoading) {
    return (
      <Container>
        <Card>
        <Spinner />
        </Card>
      </Container>
    )
  }
  

  return (
    <>
      <Container>
        <>
          {!errorMessage && (
            <>
              <HeaderSection>
                <div>
                  <PlayAudioButton onClick={handleShowModal} style={{ marginBottom: '20px' }}>
                    {t('play tutorial')} <AudioIndicator showVideoModal={showVideoModal}></AudioIndicator> 
                    <BiMoviePlay style={{ color: 'red', fontSize: '24px' }} />
                  </PlayAudioButton>
                </div>
                <CloseDiv onClick={handleClose}>
                  <MdClose size={24} color='#fff'/>
                </CloseDiv>
              </HeaderSection>
  
              {showVideoModal && (
                <TutorialVideoModel
                  videoId="ZbyfD0FC3oE"
                  onClose={handleShowModal}
                />
              )}

              <Title><h2>{t('admin account details')}</h2></Title>
              <Title><p>{t('manual bank transfer offline')}</p></Title>
              <Card>
                <Title><h3>{t('opay bank')}</h3></Title>
                <BankInfo>{t('account number')}: <b>8105932536</b></BankInfo>
                <BankInfo>{t('name')}: <b>Usama</b></BankInfo>
                <Note>{t('note')}: {t('upload receipt after sending.')}</Note>
                <CopyToClipBoardButton onClick={() => copyToClipboard('8105932536')}>{t('copy account number')}</CopyToClipBoardButton>
              </Card>
  
              {!preview && (
                <p>{t(`if you've made a transfer, click the camera icon to take a picture of the transaction slip and upload it.`)}</p>
              )}
  
              {(preview || code) && receiptStatus !== 'approved' && (
                <PreviewContainer>
                  <PreviewImage src={isFirebaseUrl(preview) ? `${baseURL}${preview}` : preview}  alt="Receipt Preview" />
                  {code && <div><p><b>{code}</b></p></div>}
                  {message && receiptStatus === 'rejected' && (
                    <MessageDiv>
                      <b><p style={{color: 'red'}}>{message}</p></b>
                    </MessageDiv>
                  )}
                  {receiptStatus === 'pending' && (
                    <>
                      <StatusMessage status={receiptStatus}>
                        <p>{t('receipt is pending for review.')} <AudioIndicator status={receiptStatus}></AudioIndicator> </p>
                      </StatusMessage>
                      
                      <Button onClick={() => handleDownload(receiptUrl)}>{t('download receipt')}</Button>
                    </>
                  )}
                  {receiptStatus !== 'pending' && <Button1 onClick={handleRetake}>{t('retake')} <TbArrowBackUp size={24} color='black' /></Button1>}
                  {/* <CloseButton><MdClose /></CloseButton> */}
                </PreviewContainer>
              )}
  
              {!preview && (
                <>
                  <InputSection>
                  <Label1 htmlFor="fileInput">{t('choose receipt')} <FaCamera size={24} color='black' /></Label1>
                  <FileInput id="fileInput" type="file" accept="image/*" onChange={handleFileChange} />
                  </InputSection>
                </>
              )}

              {!preview && (
                <>
                <InputSection>
                <Label>{t(`don't have a receipt but already sent the money?`)}</Label>
                <Textarea type='text' onChange={(e) => {setCode(e.target.value)}} placeholder='Wanna suna zamu gani, da lokacin da ka turo, ranar da time ko Code din Nita/Amana/Zeyna'/>
                </InputSection>
                </>
              )}

              {success && (<p style={{color: 'green'}}>{success}</p>)}
  
              {(preview || code?.length > 1) && receiptStatus !== 'pending' &&  <Button isLoading={isLoadingSubmit} disabled={isLoadingSubmit} isP={true} loginSuccess={success} onClick={handleSubmit}>{t('upload')} <FaUpload size={24} color={'black'} /></Button>}
  
              <Hr />
              
              <Title><h2>Foreign Transfer</h2> <TiWorld size={34} color={'black'} /></Title>
              <InfoDiv><p>{t('for those living abroad, - Niger, Libya, Algeria, Saudia ...')}</p></InfoDiv>
              
              <Card>
                <Title><h3>Nita / Amana / Zeyna - Transfer Details</h3></Title>
                <BankInfo>Lambar Waya: <b>+2348030696401</b></BankInfo>
                <BankInfo>Suna: <b>Arewa Kallo</b></BankInfo>
                <BankInfo>Adireshi: <b>Kano Nigeria</b></BankInfo>
                <Note>{t('note')}: {t('nita / amana / zeyna transfer attracts additional charges of 10% only.')}</Note>
                <CopyToClipBoardButton onClick={() => copyToClipboard('+2348030696401')}>{t('copy the number')}</CopyToClipBoardButton>
              </Card>

              <Hr />

              <Title><h2>Automatic Bank Transfer</h2></Title>
              <InfoDiv><p>{t('fund your wallet automatically by sending money to one of the account numbers below.')}</p></InfoDiv>

              {(!userWalletAccounts?.wema_bank_account && 
                !userWalletAccounts?.moniepoint && 
                !userWalletAccounts?.sterling_bank_account && 
                !userWalletAccounts?.fidelity_bank_account) && (
                <Title>{t('no available accounts found. you can send funds using the admin account details above or generate new accounts below to fund your wallet.')}</Title>
              )}
  
              {(!userWalletAccounts?.wema_bank_account && 
                !userWalletAccounts?.moniepoint && 
                !userWalletAccounts?.sterling_bank_account && 
                !userWalletAccounts?.fidelity_bank_account) && (
                  <GenerateVirtualAccounts userFullName={`${userWalletAccounts?.first_name} ${userWalletAccounts?.last_name}`} userPhone={userWalletAccounts?.phone} fetchReceipt={fetchReceiptStatus} fetchAccounts={fetchUserWalletAccounts}/>
              )}
  
              {userWalletAccounts?.wema_bank_account && (
                <Card>
                  <Title><h3>Wema Bank</h3></Title>
                  <BankInfo>{t('account number')}: <b>{userWalletAccounts?.wema_bank_account}</b></BankInfo>
                  <BankInfo>{t('name')}: Arewa Kallo - {userWalletAccounts?.first_name}</BankInfo>
                  <Note>{t('note')}: {t('automated bank transfer attracts additional charges of 1.5% only.')}</Note>
                  <CopyToClipBoardButton onClick={() => copyToClipboard(userWalletAccounts?.wema_bank_account)}>{t('copy account number')}</CopyToClipBoardButton>
                </Card>
              )}
  
              {userWalletAccounts?.sterling_bank_account && (
                <Card>
                  <Title><h3>Sterling Bank</h3></Title>
                  <BankInfo>{t('account number')}: <b>{userWalletAccounts?.sterling_bank_account}</b></BankInfo>
                  <BankInfo>{t('name')}: Arewa Kallo - {userWalletAccounts?.first_name}</BankInfo>
                  <Note>{t('note')}: {t('automated bank transfer attracts additional charges of 1.5% only.')}</Note>
                  <CopyToClipBoardButton onClick={() => copyToClipboard(userWalletAccounts?.sterling_bank_account)}>{t('copy account number')}</CopyToClipBoardButton>
                </Card>
              )}

              {userWalletAccounts?.fidelity_bank_account && (
                <Card>
                  <Title><h3>Fidelity Bank</h3></Title>
                  <BankInfo>{t('account number')}: <b>{userWalletAccounts?.fidelity_bank_account}</b></BankInfo>
                  <BankInfo>{t('name')}: Arewa Kallo - {userWalletAccounts?.first_name}</BankInfo>
                  <Note>{t('note')}: {t('automated bank transfer attracts additional charges of 1.5% only.')}</Note>
                  <CopyToClipBoardButton onClick={() => copyToClipboard(userWalletAccounts?.fidelity_bank_account)}>{t('copy account number')}</CopyToClipBoardButton>
                </Card>
              )}

              {userWalletAccounts?.gt_bank_account && (
                <Card>
                  <Title><h3>GTBANK</h3></Title>
                  <BankInfo>{t('account number')}: <b>{userWalletAccounts?.gt_bank_account}</b></BankInfo>
                  <BankInfo>{t('name')}: Arewa Kallo - {userWalletAccounts?.first_name}</BankInfo>
                  <Note>{t('note')}: {t('automated bank transfer attracts additional charges of 1.5% only.')}</Note>
                  <CopyToClipBoardButton onClick={() => copyToClipboard(userWalletAccounts?.gt_bank_account)}>{t('copy account number')}</CopyToClipBoardButton>
                </Card>
              )}
          
            </>
          )}
        </>
        {errorMessage && (
          <ErrorMessageModal errorMessage={errorMessage} onClose={() => {setErrorMessage('')}}/>
        )} 
      </Container>
    </>
  );  
};

export default AddFundModel;
